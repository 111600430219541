const AppSettings = {
    appTitle: 'Nomadis',
    mapsalgerie: '34.442277, 3.492232',
    zoomalgerie : 7,
	     devise : 'dinars',
        millimes: 'mellimes',
        module:"Nomadis",
    //keymaps : 'AIzaSyAa5j5PXOoThi9oSiADE6wzQLNbstY3Q-g', //deprecated

    //keymaps : 'AIzaSyA1nTGqD3IpFiMrPjLH2vLRrRnKB8bJf1Q', //deprecated
   // keymaps :'AIzaSyAEr8rZABZe_F6-sMe1z7II5nqP1B_IW4E',
     keymaps :'AIzaSyAMZBIUbxC0z-je7wnaB0Jk5J3FSDT-Vzs',

     // apiUrl: 'https://api.nomadis.tantoushgroup.online/api/v1/',
        //apiUrl: 'https://api.sunia.tantoushgroup.online/api/v1/',
      //  apiUrl:'https://api.sancella.clediss.ovh/api/v1/',
         apiUrl:'https://api.nomadis.satem.online/api/v1/',
       // apiUrl: 'https://api.nomadis.sancella.online/api/v1/',

        herewego : {
                app_id : 'VxpPmyKjSh1FcbqCq7d7',
                app_code : 'sjYx8madd1LAp_IP0Qo1GQ'
        }
        ,
        //using mapbox accesstocken
        mapbox : {
                access_token : 'pk.eyJ1IjoiY2xlZGlzc25vbWFkaXMiLCJhIjoiY2p0b2w1MHJqMDU2czQ1cG5tY3Bu$'
        }
,
        "lang": "fr_FR"
};
export default AppSettings;
